export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FULFILLED = "LOGIN_FULFILLED";
export const LOGIN_REJECTED = "LOGIN_REJECTED";

export const REGISTER_PENDING = "REGISTER_PENDING";
export const REGISTER_FULFILLED = "REGISTER_FULFILLED";
export const REGISTER_REJECTED = "REGISTER_REJECTED";

export const LOGOUT = "LOGOUT";

export const RESET_PASSWORD_PENDING = "RESET_PASSWORD_PENDING";
export const RESET_PASSWORD_FULFILLED = "RESET_PASSWORD_FULFILLED";
export const RESET_PASSWORD_REJECTED = "RESET_PASSWORD_REJECTED";

export const INIT_PASSWORD_PENDING = "INIT_PASSWORD_PENDING";
export const INIT_PASSWORD_FULFILLED = "INIT_PASSWORD_FULFILLED";
export const INIT_PASSWORD_REJECTED = "INIT_PASSWORD_REJECTED";

export const FORGOT_PASSWORD_PENDING = "FORGOT_PASSWORD_PENDING";
export const FORGOT_PASSWORD_FULFILLED = "FORGOT_PASSWORD_FULFILLED";
export const FORGOT_PASSWORD_REJECTED = "FORGOT_PASSWORD_REJECTED";

export const REGISTER_VENDOR_PENDING = "REGISTER_VENDOR_PENDING";
export const REGISTER_VENDOR_FULFILLED = "REGISTER_VENDOR_FULFILLED";
export const REGISTER_VENDOR_REJECTED = "REGISTER_VENDOR_REJECTED";

export const RETURN_EMPLOYEE_SCORE_PENDING = "RETURN_EMPLOYEE_SCORE_PENDING";
export const RETURN_EMPLOYEE_SCORE_FULFILLED =
  "RETURN_EMPLOYEE_SCORE_FULFILLED";
export const RETURN_EMPLOYEE_SCORE_REJECTED = "RETURN_EMPLOYEE_SCORE_REJECTED";

export const SET_COMPLIST_PENDING = "SET_COMPLIST_PENDING";
export const SET_COMPLIST_FULFILLED = "SET_COMPLIST_FULFILLED";
export const SET_COMPLIST_REJECTED = "SET_COMPLIST_REJECTED";
