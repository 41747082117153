import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction, authAction } from "redux/modules";
import showMessage from "hooks/showMessage";
import showErrMessage from "hooks/showErrMessage";
import HomeSurveyResult from "./HomeSurveyResult";

const HomeSurvey = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const surveyList = useSelector(store => store.home.surveyList);
  const selectedOption = useSelector(store => store.home.selectedOption);
  var surveys = null;
  const choiceList = [];
  const [selectedChoice, setSelectedChoice] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  useEffect(() => {
    return () => {};
  }, [surveyList]);

  function onClickOption(value, choiceId) {
    choiceList.push(choiceId);
    setSelectedChoice(choiceList);
    dispatch(homeAction.changeOption(value));
  }

  function showChoices(choicesList) {
    var surveyChoices = null;
    surveyChoices = choicesList.map((item, index) => (
      <div
        key={index}
        className="d-flex align-items-center mr-md-4"
        onClick={() => onClickOption(index + 1, item.id)}
      >
        <div
          className={
            selectedOption === index + 1 ? "option selected" : "option"
          }
          data-id={index + 1}
        >
          <div></div>
        </div>
        <div
          className="option-txt"
          dangerouslySetInnerHTML={{ __html: item.name }}
        ></div>
      </div>
    ));
    return surveyChoices;
  }

  function saveSurvey(surveys, surveyChoice, employeeFirmId, firmId, score, e) {
    if (surveyChoice.length <= 0) {
      showErrMessage("Seçenek seçiniz.");
      e.preventDefault();
    } else {
      setDisabledButton(true);
      dispatch(
        homeAction.saveSurveyAnswer(
          surveys,
          surveyChoice,
          employeeFirmId,
          firmId
        )
      )
        .then(e => {
          showMessage(
            "Tebrikler! Ankete cevap vererek " + score + " puan kazandınız."
          );
          dispatch(
            authAction.returnNewScoreOfEmployee(
              employeeFirm.firm.id,
              employeeFirm.id
            )
          );
        })
        .catch(err => {
          showErrMessage("Seçenek seçiniz.");
        });
    }
  }

  if (surveyList) {
    surveys = surveyList.map(item => {
      return (
        <div key={item.id}>
          {item.answeredSurvey ? (
            <HomeSurveyResult />
          ) : (
            <div className="content">
              <div>
                <h2>ANKET</h2>
                <p className="subhead">{item.description}</p>
              </div>
              <form
                name="anket"
                onSubmit={e => {
                  e.preventDefault();
                }}
                style={{ paddingBottom: 30 }}
              >
                <div className="d-flex options flex-column flex-md-row">
                  <input
                    type="hidden"
                    name="anket"
                    value={selectedOption || ""}
                  />
                  {showChoices(
                    item.surveyChoices,
                    item.answeredSurvey,
                    item.totalParticipant
                  )}
                </div>
                <div className="text-md-right mt-4">
                  <button
                    type="submit"
                    className="arrowButton d-inline-block"
                    onClick={e =>
                      saveSurvey(
                        item.id,
                        selectedChoice,
                        employeeFirm.id,
                        employeeFirm.firm.id,
                        item.score,
                        e
                      )
                    }
                    disabled={disabledButton}
                  >
                    OY VER
                  </button>
                </div>
              </form>
            </div>
          )}
        </div>
      );
    });
  } else {
    surveys = <div> </div>;
  }

  return (
    <div id="anasayfa-anket" className={"pt-md-4 pb-md-3"}>
      <div className="container">
        <div className="d-flex">
          <div className="d-none d-md-block" style={{ maxWidth: "300px" }}>
            <img src="../portal-resource/img/anket.jpg" />
          </div>
          {surveys}
        </div>
      </div>
    </div>
  );
};
export default HomeSurvey;
