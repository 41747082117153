import * as userTypes from "./user.type";

import { API } from "../../../services/http.service";
import { SUCCESS_MESSAGES } from "../../../constants/messages.const";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getUserById = userId => async dispatch => {
  dispatch({ type: userTypes.GET_USER_PENDING });

  try {
    const response = await API.get(
      `https://jsonplaceholder.typicode.com/users/${userId}`,
      config
    );
    dispatch({ type: userTypes.GET_USER_FULFILLED, payload: response });
  } catch (error) {
    dispatch({ type: userTypes.GET_USER_REJECTED, payload: error });
  }
};

function returnCatList(text) {
  return text;
}

export const setLoginedUser = text => async dispatch => {
  dispatch({
    type: userTypes.SET_LOGINED_USER_PENDING
  });

  try {
    const returnComponent = returnCatList(text);
    dispatch({
      type: userTypes.SET_LOGINED_USER_FULFILLED,
      payload: returnComponent
    });
  } catch (error) {
    dispatch({
      type: userTypes.SET_LOGINED_USER_REJECTED,
      payload: error
    });
  }
};
