import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../config/api.config";
import { API } from "services/http.service";

const HomeBrand = props => {
  const [isEOrderCustomer, setIsEOrderCustomer] = useState(false);

  return (
    <div>
      <section className="main-brands">
        <div className="brands-container">
          {!isEOrderCustomer && (
            <div className="brands-logo">
              <img src="../portal-resource/img/main-brands-logo.png" alt="" />
              <h3>MARKALARIMIZ</h3>
            </div>
          )}

          {isEOrderCustomer && (
            <Link
              className="brands-item p-0"
              to={{
                pathname: "/userportal/eorderBridge"
              }}
            >
              <img src="/portal-resource/img/eorder-home-green.png" />
            </Link>
          )}
        </div>
      </section>
    </div>
  );
};
export default HomeBrand;
