import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// Components
import { PrivateRoute } from "../components";

// Pages
import Home from "./home/home";
import NotFound from "./not-found/not-found";
import User from "./user/user";
import Login from "./login/login";
import SignUp from "./signUp/SignUp";
import PasswordReset from "./passwordReset/PasswordReset";

// TODO: Lazy loading
import { ROUTES } from "../constants/routes.const";
import withLayout from "./userportal/withLayout";

const Router = props => {
  const isAuthenticated = useSelector(store => store.auth.isAuthenticated);

  return (
    <Switch>
      <Route path="/login" component={Login} exact />
      <Route path="/signUp" component={SignUp} exact />
      <Route path="/passwordReset" component={PasswordReset} exact />

      <PrivateRoute
        path="/"
        component={withLayout(Home)}
        exact
        isAuthenticated={isAuthenticated}
      />
      <PrivateRoute
        path="/user"
        component={withLayout(User)}
        exact
        isAuthenticated={isAuthenticated}
      />

      <Route path={ROUTES.NOT_FOUND} component={NotFound} exact />
      <Route component={() => <Redirect to={ROUTES.NOT_FOUND} />} />
    </Switch>
  );
};

export default Router;
