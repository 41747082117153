import React from "react";
const HomeGame = props => {
  return (
    <div>
      <div id="anasayfa-soru">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <div className="box mb-3 mb-md-0">
                <div className="img text-center">
                  <a href={"/userportal/searchFind"}>
                    <img src="../portal-resource/img/soruyu_bul.png" alt="" />
                  </a>
                </div>
                <a
                  href="/userportal/searchFind"
                  className="txt d-block text-center"
                >
                  ARA BUL
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box mb-3 mb-md-0">
                <div className="img text-center">
                  <a href={"/userportal/pickFind"}>
                    <img src="../portal-resource/img/bak_bul.png" alt="" />
                  </a>
                </div>
                <a
                  href="/userportal/pickFind"
                  className="txt d-block text-center"
                >
                  SEÇ BUL
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box mb-3 mb-md-0">
                <div className="img text-center">
                  <a href={"/userportal/lookFind"}>
                    <img src="../portal-resource/img/sec_bul.png" alt="" />
                  </a>
                </div>
                <a
                  href="/userportal/lookFind"
                  className="txt d-block text-center"
                >
                  BAK BUL
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="box mb-3 mb-md-0">
                <div className="img text-center">
                  <a href={"/userportal/weekStore"}>
                    <img
                      src="../portal-resource/img/haftaninDukkaniHome.png"
                      alt=""
                    />
                  </a>
                </div>
                <a
                  href="/userportal/weekStore"
                  className="txt d-block text-center"
                >
                  HAFTANIN DÜKKANI
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default HomeGame;
