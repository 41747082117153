import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { profileAction } from "redux/modules";
import { Link } from "react-router-dom";
import { imageShowUrl } from "../../../config/api.config";
/* import { profileAction } from "redux/modules";
 */
const UserportalFooter = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {};
  }, []);

  const actives = [];
  /* 
  function goToAdress(key, index2) {
    [0, 1, 2, 3, 4, 5].map((item, index) => {
      actives.push(false);
    });

    actives.map((item, index) => {
      index === index2 ? (actives[index] = true) : (actives[index] = false);
    });

    dispatch(profileAction.setActives(actives));
    dispatch(profileAction.setProfileContent(key));
  } */

  return (
    <div className="clear">
      <footer>
        <div className="footer-container">
          <div className="footer-top">
            <div className="footer-menu">
              <ul>
                <li>
                  <a href="/userportal/faq">Sıkça Sorulan Sorular</a>
                </li>
                <li>
                  <a href="/userportal/tac">Şart ve Koşullar</a>
                </li>
                <li>
                  <a href="/userportal/userGuide">Kullanım Kılavuzu</a>
                </li>
                <li>
                  <a href="/userportal/score">Nasıl Puan Kazanırım</a>
                </li>
                <li>
                  <li>
                    <a href="/userportal/announcement">Duyurular</a>
                    {/*  <Link
                      to={{ pathname: "/userportal/profile", from: "footer" }}
                      onClick={() => {
                        goToAdress("message", 2);
                      }}
                    >
                      Bize Ulaşın
                    </Link> */}
                  </li>
                </li>
                <li>
                  <Link
                    to={{ pathname: "/userportal/profile", from: "footer" }}
                  >
                    Bize Ulaşın
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default UserportalFooter;
