import { authAction } from "../modules";

/**
 * When the http status is 401, it will automatically dispatch for logout
 * @param {import('redux').Dispatch} Object
 */
export const handleAuthenticated = ({ dispatch }) => next => action => {
  if (action.payload && action.payload.status === 401) {
    // Refresh Token olmalı.
    dispatch(authAction.logout());
  } else {
    next(action);
  }
};
