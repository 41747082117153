/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { imageShowUrl } from "../../config/api.config";
import { API } from "services/http.service";

const HomePrice = props => {
  const [priceListImage, setPriceListImage] = useState("");

  useEffect(() => {
    const asyncPriceListImageData = async () => {
      const response = await API.get("price/Fiyat Listesi");
      setPriceListImage(response);
    };
    asyncPriceListImageData();
    console.log(priceListImage);
  }, [priceListImage === null]);

  return (
    <div>
      <section className="main-service-card">
        <div className="main-service-container">
          <div className="main-service-item first-service">
            <div className="main-service-text">
              <h4>JTI FİYAT LİSTESİ</h4>
              <a
                data-fancybox="gallery"
                href={
                  priceListImage ? imageShowUrl + priceListImage.data : null
                }
              >
                DETAYLI BİLGİ
              </a>
            </div>
            <div className="main-service-content">
              <div className="main-service-content-list">
                <ul>
                  <li>CAMEL YELLOW 100 / 40TL</li>
                  <li>WINSTON SLENDER DARK BLUE & DEEP BLUE / 15 TL</li>
                  <li>WINSTON SLENDER / 15 TL</li>
                  <li>WINSTON DARK BLUE & DEEP BLUE / 15 TL</li>
                </ul>
                <ul>
                  <li>WINSTON / 16 TL</li>
                  <li>CAMEL SLENDER / 14,50 TL</li>
                  <li>CAMEL / 15 TL</li>
                </ul>
                <ul>
                  <li>LD / 14,50 TL</li>
                  <li>MONTE CARLO SLENDER / 14,50 TL</li>
                  <li>MONTE CARLO / 14,50 TL</li>
                </ul>
                {/* <ul className="w-100 d-flex flex-wrap">
                  {!priceList && <li>Eklenmiş herhangi bir fiyat yoktur</li>}
                  {priceList &&
                    [1, 2, 3].map((element, index) =>
                      priceList.map(
                        (element2, index2) =>
                          element2.rowOrder &&
                          element2.rowOrder === element && (
                            <li style={{ width: "33.3%" }}>
                              {element2.columnOrder === 1 &&
                                `${element2.name.toUpperCase()} ${
                                  element2.price
                                } TL`}
                              {element2.columnOrder === 2 &&
                                `${element2.name.toUpperCase()} ${
                                  element2.price
                                } TL`}
                              {element2.columnOrder === 3 &&
                                `${element2.name.toUpperCase()} ${
                                  element2.price
                                } TL`}
                            </li>
                          )
                      )
                    )}
                </ul> */}
              </div>
            </div>
          </div>
          <div
            className="main-service-item other-item"
            style={{ display: "none" }}
          >
            <div className="main-service">
              <img src="../portal-resource/img/main-img-2.jpg" alt="" />
            </div>
            <div className="main-service-text">
              <h4>ANKET</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore
              </p>
              <a href="#">Detaylı Bilgi ></a>
            </div>
          </div>
          <div
            className="main-service-item other-item"
            style={{ display: "none" }}
          >
            <div className="main-service-text">
              <h4>SIRALAMA</h4>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
                eiusmod tempor incididunt ut labore et dolore
              </p>
              <a href="#">Detaylı Bilgi ></a>
            </div>
            <div className="main-service">
              <img src="../portal-resource/img/main-img-3.jpg" alt="" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
export default HomePrice;
