import * as userTypes from "./user.type";

const initialState = {
  user: null,
  error: null,
  isLoading: false,
  loginedUser: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${userTypes.GET_USER_PENDING}`:
    case `${userTypes.SET_LOGINED_USER_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${userTypes.GET_USER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        user: payload.data
      };
    case `${userTypes.GET_USER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        user: null
      };
    case `${userTypes.SET_LOGINED_USER_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        loginedUser: payload
      };
    case `${userTypes.SET_LOGINED_USER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };
    default:
      return state;
  }
};

export default reducer;
