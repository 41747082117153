import React, { useEffect } from "react";
import HomeAnnouncement from "./HomeAnnouncement";
import HomeBrand from "./HomeBrand";
import HomePrice from "./HomePrice";
import HomeSurvey from "./HomeSurvey";
import HomeGame from "./HomeGames";
import HomeFocus from "./HomeFocus";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "redux/modules";
import Swiper from "swiper";
import { useHistory } from "react-router";

const Home = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  const loginedUser = useSelector(store => store.user.loginedUser);
  const logined = props.location.logined;
  useEffect(() => {
    const reloadControl = localStorage.getItem("reControl");

    if (!reloadControl) {
      localStorage.setItem("reControl", "true");
      // window.location.reload();
      dispatch(userAction.setLoginedUser("logined"));
    }

    setTimeout(() => {
      window.mainPageSlider = new Swiper(".swiper-main-slider", {
        slidesPerView: 1,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        autoplay: {
          delay: 5000
        },
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function(index, className) {
            return '<span class="' + className + '">' + (index + 1) + "</span>";
          }
        },
        loop: true
      });
    }, 300);

    return () => {};
  }, [loginedUser]);

  return (
    <div>
      {loginedUser && logined ? null : <HomeFocus />}
      <HomeAnnouncement />
      <HomeBrand />
      <HomePrice />
      <HomeSurvey />
      <HomeGame />
    </div>
  );
};

export default Home;
