export const ERROR_MESSAGES = {
  401: "Kimlik doğrulaması başarısız.",
  403: "Bu işlem için yetkiniz yoktur.",
  404: "Servis bulunamadı.",
  500: "Beklenmedik bir hata oluştu.",
  504: "İstek zaman aşımına uğradı.",
  default: "İşlem başarısız."
};

export const SUCCESS_MESSAGES = {
  RESET_PASSWORD:
    "Şifre sıfırlama bağlantısı e-posta adresine gönderimi yapıldı.",
  INIT_PASSWORD: "Yeni şifre tanımlandı."
};
