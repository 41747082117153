import React from "react";
import Router from "./pages/Router";
import "bootstrap/dist/css/bootstrap.css";
import { useHandleRouteWhenAuth } from "hooks";
import _ from "lodash";
global._ = _;

function App() {
  useHandleRouteWhenAuth();

  return <Router />;
}

export default App;
