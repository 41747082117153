import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "redux/modules";
import { imageShowUrl } from "../../config/api.config";
import { videoShowUrl } from "../../config/api.config";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { API } from "../../services/http.service";

const HomeAnnouncement = props => {
  const history = useHistory();
  const dispatch = useDispatch();
  const announcementList = useSelector(store => store.home.announcementList);
  const [priceListImage, setPriceListImage] = useState("");

  let announcements = null;

  useEffect(() => {
    return () => {};
  }, [announcementList]);

  useEffect(() => {
    const asyncPriceListImageData = async () => {
      const response = await API.get("price/Fiyat Listesi");
      setPriceListImage(response);
    };
    asyncPriceListImageData();
  }, [priceListImage === null]);

  const camelFunction = url => {
    console.log(url);

    localStorage.setItem("camel100", "true");
    history.push(url);
  };

  if (announcementList) {
    announcements = announcementList.map(item => {
      return (
        <div className="swiper-slide" key={item.id}>
          {item.newTab ? (
            <a href={item.url} target="_blank">
              <div className="main-slider-bg main-slider-desktop">
                <img
                  src={imageShowUrl + item.icon}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="main-slider-bg main-slider-mobil">
                <img
                  src={imageShowUrl + item.iconPhone}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </a>
          ) : (
            <div>
              {item.videoUrl ? (
                <a href={videoShowUrl + item.videoUrl} data-fancybox="video">
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              ) : item.name == "Camel 100" ? (
                <Link
                  to={{
                    pathname: "/userportal/brand",
                    brandId: 149465,
                    brandIndex: 2,
                    handleImgClick: true
                  }}
                >
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </Link>
              ) : item.name === "FİYAT DEĞİŞİKLİĞİ OLMADI DUYURUSU" ? (
                <a
                  data-fancybox="gallery"
                  href={
                    priceListImage ? imageShowUrl + priceListImage.data : null
                  }
                >
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              ) : (
                <a href={item.url}>
                  <div className="main-slider-bg main-slider-desktop">
                    <img
                      src={imageShowUrl + item.icon}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                  <div className="main-slider-bg main-slider-mobil">
                    <img
                      src={imageShowUrl + item.iconPhone}
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </a>
              )}
            </div>
          )}
        </div>
      );
    });
  }

  return (
    <section
      className="main-slider main-slider-homepage"
      id="main-slider-announcement"
    >
      <div className="main-slider-container">
        <div className="swiper-container swiper-main-slider">
          <div className="swiper-wrapper">{announcements}</div>
          <div className="swiper-button-next"></div>
          <div className="swiper-button-prev"></div>
          <div className="swiper-pagination"></div>
        </div>
      </div>
    </section>
  );
};
export default HomeAnnouncement;
