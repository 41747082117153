import * as authTypes from "./auth.type";

const initialState = {
  isAuthenticated: false,
  authUser: null,
  error: null,
  isLoading: false,
  employeeFirm: null,
  compList: null
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${authTypes.LOGIN_PENDING}`:
    case `${authTypes.REGISTER_PENDING}`:
    case `${authTypes.RESET_PASSWORD_PENDING}`:
    case `${authTypes.INIT_PASSWORD_PENDING}`:
    case `${authTypes.FORGOT_PASSWORD_PENDING}`:
    case `${authTypes.REGISTER_VENDOR_PENDING}`:
    case `${authTypes.RETURN_EMPLOYEE_SCORE_PENDING}`:
    case `${authTypes.SET_COMPLIST_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${authTypes.LOGIN_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        authUser: payload.data
      };
    case `${authTypes.LOGIN_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload,
        isAuthenticated: false,
        authUser: null
      };

    // case `${authTypes.RETURN_EMPLOYEE_SCORE_FULFILLED}`:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     employeeFirm: payload.data
    //   };
    // case `${authTypes.RETURN_EMPLOYEE_SCORE_REJECTED}`:
    //   return {
    //     ...state,
    //     isLoading: false,
    //     error: payload
    //   };

    case `${authTypes.SET_COMPLIST_FULFILLED}`:
      console.log("payload.data::: ", payload);

      return {
        ...state,
        isLoading: false,
        compList: payload
      };
    case `${authTypes.SET_COMPLIST_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${authTypes.REGISTER_FULFILLED}`:
    case `${authTypes.RESET_PASSWORD_FULFILLED}`:
    case `${authTypes.INIT_PASSWORD_FULFILLED}`:
    case `${authTypes.FORGOT_PASSWORD_FULFILLED}`:
    case `${authTypes.REGISTER_VENDOR_FULFILLED}`:
      return { ...state, isLoading: false };

    case `${authTypes.REGISTER_REJECTED}`:
    case `${authTypes.RESET_PASSWORD_REJECTED}`:
    case `${authTypes.INIT_PASSWORD_REJECTED}`:
    case `${authTypes.FORGOT_PASSWORD_REJECTED}`:
    case `${authTypes.REGISTER_VENDOR_REJECTED}`:
      return { ...state, isLoading: false, error: payload };

    case `${authTypes.LOGOUT}`:
      return { ...initialState, isAuthenticated: false };

    default:
      return state;
  }
};

export default reducer;
