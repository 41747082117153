import * as homeTypes from "./home.type";

import { API } from "../../../services/http.service";

const REQUEST_URL = "";

const config = {
  headers: { "Content-Type": "application/json-application" }
};

export const getAnnouncementByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/announcementList/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const getSurveyByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_SURVEYLIST_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/survey/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_SURVEYLIST_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SURVEYLIST_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const saveSurveyAnswer = (
  survey,
  surveyChoice,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: homeTypes.SAVE_SURVEY_ANSWER_PENDING });

  const data = { survey, surveyChoice, employeeFirmId };
  try {
    const response = await API.post(`/survey/save`, data);
    const interaction = {
      message: "Ankete katıldınız",
      type: "notification"
    };

    dispatch({
      type: homeTypes.SAVE_SURVEY_ANSWER_FULFILLED,
      payload: response,
      interaction
    });

    dispatch(getSurveyByFirmId(firmId, employeeFirmId));
    //    dispatch(authAction.returnNewScoreOfEmployee(firmId,employeeFirmId));
    dispatch({
      type: homeTypes.SELECTED_OPTION,
      payload: null
    });
  } catch (error) {
    dispatch({ type: homeTypes.SAVE_SURVEY_ANSWER_REJECTED, payload: error });
  }
};

export const getDisclaimerByFirmId = (id, employeeId) => async dispatch => {
  dispatch({ type: homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/disclaimer/${id}/${employeeId}`,
      config
    );
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};

export const changeOption = selectedOption => async dispatch => {
  dispatch({ type: homeTypes.SELECTED_OPTION, payload: selectedOption });
};

export const saveDisclaimer = (
  disclaimer,
  employeeFirmId,
  firmId
) => async dispatch => {
  dispatch({ type: homeTypes.SAVE_DISCLAIMER_CHECK_PENDING });

  const data = { disclaimer, employeeFirmId, firmId };
  try {
    const response = await API.post(`/disclaimer/save`, data);

    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED,
      payload: response
    });

    //    dispatch(getDisclaimerByFirmId(firmId));
  } catch (error) {
    dispatch({
      type: homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED,
      payload: error
    });
  }
};

export const getSpecialActivityByFirmId = id => async dispatch => {
  dispatch({ type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING });

  try {
    const response = await API.get(
      `${REQUEST_URL}/specialActivityList/${id}`,
      config
    );
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED,
      payload: response
    });
  } catch (error) {
    dispatch({
      type: homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED,
      payload: error
    });
  }
};
