import thunk from "redux-thunk";
import { createLogger } from "redux-logger";
import { handleAuthenticated } from "./authentication.middleware";
import { handleAuthorization } from "./authorization.middleware";
import { handleAnalytics } from "./analytics.middleware";

import { isActiveReduxLogger } from "../../config/dev.config";

const MIDDLEWARES = [
  thunk,
  handleAuthenticated,
  handleAuthorization,
  handleAnalytics
];

if (process.env.NODE_ENV === "development") {
  isActiveReduxLogger && MIDDLEWARES.push(createLogger({}));
}

export const Middlewares = {
  get() {
    return MIDDLEWARES;
  }
};
