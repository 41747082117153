import React, { Component } from "react";
import UserportalMenu from "./userportal-menu/UserportalMenu";
import UserportalFooter from "./userportal-footer/UserportalFooter";

const withLayout = OriginalComponent =>
  class extends Component {
    render() {
      return (
        <>
          <UserportalMenu />
          <OriginalComponent {...this.props} />
          <UserportalFooter />
        </>
      );
    }
  };

export default withLayout;
