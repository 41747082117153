import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import {
  faBell,
  faComments,
  faCompass,
  faEnvelopeOpen,
  faPenNib,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";

const PublicFooter = ({ history }) => {
  return (
    <footer className="footer">
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={12} md={6}>
            <Row>
              <Col>
                <img src={Logo} alt="Footer Logo" />
              </Col>

              <Col className="d-flex flex-column text-right">
                <h3>BİZİ TAKİP EDİN</h3>
                <ul className="social-networks justify-content-end">
                  <li>
                    <a href="#" title="Facebook">
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>

                  <li>
                    <a href="#" title="Instagram">
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>

                  <li>
                    <a href="#" title="Twitter">
                      <FontAwesomeIcon icon={faTwitter} />
                    </a>
                  </li>
                </ul>

                <div className="footer-contact">
                  <h3>İLETİŞİM</h3>
                  <a href="mailto:info@jarvis.com">info@jarvis.com</a>
                  <a href="tel:905555454554">905555454554</a>
                </div>
              </Col>
            </Row>
          </Col>

          {/* <Col xs={12} md={4}>
            <ul className="footer-menu">
              <li>
                <a href="#">
                  <span>Sıkça Sorulan Sorular</span>
                  <FontAwesomeIcon icon={faComments} />
                </a>
              </li>

              <li>
                <a href="#">
                  <span>Şart ve Koşullar</span>
                  <FontAwesomeIcon icon={faPenNib} />
                </a>
              </li>

              <li>
                <a href="">
                  <span>Kullanım Kılavuzu</span>
                  <FontAwesomeIcon icon={faCompass} />
                </a>
              </li>

              <li>
                <a href="">
                  <span>Nasıl Puan Kazanırım</span>
                  <FontAwesomeIcon icon={faQuestion} />
                </a>
              </li>

              <li>
                <a href="">
                  <span>Duyurular</span>
                  <FontAwesomeIcon icon={faBell} />
                </a>
              </li>

              <li>
                <a href="">
                  <span>Bize Ulaşın</span>
                  <FontAwesomeIcon icon={faEnvelopeOpen} />
                </a>
              </li>
            </ul>
          </Col> */}
        </Row>
      </Container>
    </footer>
  );
};

export default PublicFooter;
