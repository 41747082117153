import React, { useState } from "react";
import { useDispatch } from "react-redux";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import "./signUp.css";
import validatePasswords from "hooks/validatePasswords";
import NumberFormat from "react-number-format";
import { authAction } from "redux/modules";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faInstagram,
  faTwitter
} from "@fortawesome/free-brands-svg-icons";
import {
  faBell,
  faComments,
  faCompass,
  faEnvelopeOpen,
  faPenNib,
  faQuestion
} from "@fortawesome/free-solid-svg-icons";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PublicFooter from "../PublicFooter/PublicFooter";

const SingUp = props => {
  const dispatch = useDispatch();
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [mobileNo, setMobileNo] = useState("");
  const [vendorName, setVendorName] = useState("");
  //  const [vendorERPCode, setVendorERPCode] = useState("");
  const [tapdkCode, setTapdkCode] = useState("");
  const [tapdkType, setTapdkType] = useState("");
  const [showKvkk, setShowKvkk] = useState(false);
  const [showEnlightenment, setShowEnlightenment] = useState(false);
  //  const [username, setUsername] = useState("");
  const [vendorERCCode, setVendorERCCode] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [type, setType] = useState("0");

  const [showERC, setShowERC] = useState(false);
  const handleERCClose = () => setShowERC(false);
  const handleERCShow = () => setShowERC(true);

  const handleKvkkShow = () => setShowKvkk(true);
  const handleKvkkClose = () => setShowKvkk(false);

  const handleEnlightenmentShow = () => setShowEnlightenment(true);
  const handleEnlightenmentClose = () => setShowEnlightenment(false);

  toast.success("🦄 Wow so easy!", {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });

  function checkRequiredFields(e) {
    if (name.length <= 0) {
      showErrMessage("Adınızı Giriniz");
      e.preventDefault();
    } else if (surname.length <= 0) {
      showErrMessage("Soyadınızı Giriniz");
      e.preventDefault();
    } else if (mobileNo.length < 9) {
      showErrMessage("Cep Telefonu Giriniz");
      e.preventDefault();
    } else if (vendorName.length <= 0) {
      showErrMessage("Bayi Tabela Adı Giriniz");
      e.preventDefault();
    }
    // else if (vendorERPCode.length <= 0) {
    //   showErrMessage("Bayi Kodu Giriniz");
    //   e.preventDefault();
    // }
    else if (tapdkCode.length <= 0) {
      showErrMessage("Satış Belgesi Numaranızı Giriniz");
      e.preventDefault();
    } else if (tapdkType.length <= 0) {
      showErrMessage("Satış Belgesi Tipi Seçiniz");
      e.preventDefault();
    } else if (!document.getElementById("infoCheck").checked) {
      showErrMessage(
        "Kişisel veriler hakkında bildirim ve aydınlatma metnini kabul ediniz."
      );
      e.preventDefault();
    }

    // else if (username.length <= 0) {
    //   showErrMessage("Kullanıcı Adı Giriniz");
    //   e.preventDefault();
    //}
    else if (vendorERCCode.length <= 0) {
      showErrMessage("ERC Kodu Giriniz");
      e.preventDefault();
    } else if (password.length <= 0) {
      showErrMessage("Şifre Giriniz");
      e.preventDefault();
    } else if (confirmPassword.length <= 0) {
      showErrMessage("Şifre (Tekrar) Giriniz");
      e.preventDefault();
    } else if (password.length > 0 && password.length < 4) {
      showErrMessage("Girilen şifre en az 4 karakterli olmalıdır!");
      e.preventDefault();
    } else if (confirmPassword.length > 0 && confirmPassword.length < 4) {
      showErrMessage("Girilen şifre (Tekrar) en az 4 karakterli olmalıdır!");
      e.preventDefault();
    } else {
      if (validatePasswords(password, confirmPassword)) {
        dispatch(
          authAction.registerVendor({
            name,
            surname,
            mobileNo,
            vendorName,
            //vendorERPCode,
            tapdkCode,
            tapdkType,
            //username,
            vendorERCCode,
            password
          })
        )
          .then(e => {
            if (e.isHttpSuccess) {
              showMessage("Kayıt Başarıyla Oluşturuldu.");
              setName("");
              setSurname("");
              setMobileNo("");
              setVendorName("");
              //setVendorERPCode("");
              setTapdkCode("");
              setTapdkType("");
              //setUsername("");
              setVendorERCCode("");
              setPassword("");
              setConfirmPassword("");
            }
          })
          .catch(err => {
            if (err.error.response.data.errors) {
              err.error.response.data.errors.map(item => {
                showErrMessage(item.message);
              });
            }
          });
      }
    }
  }

  return (
    <div className="public-container">
      <div className="content">
        <div className="public-container-content">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <header className="text-left">
                  <a href="/login" className="d-inline-block">
                    <img src={Logo} alt="Jarvis Connection" />
                  </a>
                  <h2>YENİ ÜYE OL</h2>
                </header>
                <section className="login-page signup-page">
                  <Row className="justify-content-md-center">
                    <Col xs={12} md={12}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Üye Adı</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Ad"
                            value={name}
                            onChange={e => setName(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Üye Soyadı</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Soyad"
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Telefon</Form.Label>
                          <NumberFormat
                            className="form-control"
                            allowEmptyFormatting
                            placeholder=" (__) ___ __ __"
                            format="0 (5##) ### ## ##"
                            mask="_"
                            value={mobileNo}
                            onValueChange={values => {
                              setMobileNo(values.value);
                            }}
                          />
                          <div className="form-el-line sign-up-line"></div>
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Kullanıcı Adı</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Kullanıcı Adı"
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Bayi Tabela Adı</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Bayi Tabela Adı"
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Satış Belgesi Numarası</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Satış Belgesi Numarası"
                            value={surname}
                            onChange={e => setSurname(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicSelect">
                          <Form.Label>Satış Belgesi Tipi</Form.Label>
                          <Form.Control
                            as="select"
                            value={type}
                            onChange={e => {
                              console.log("e.target.value", e.target.value);
                              setType(e.target.value);
                            }}
                          >
                            <option value="0">Seçiniz</option>
                            <option value="P">P</option>
                            <option value="PTT">PT</option>
                          </Form.Control>
                        </Form.Group>

                        <div className="w-100 d-flex flex-row">
                          <div className="w-50">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Şifre</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Şifre"
                                value={surname}
                                onChange={e => setSurname(e.target.value)}
                              />
                            </Form.Group>
                          </div>

                          <div className="w-50">
                            <Form.Group controlId="formBasicPassword">
                              <Form.Label>Şifre Tekrar</Form.Label>
                              <Form.Control
                                type="password"
                                placeholder="Şifre Tekrar"
                                value={surname}
                                onChange={e => setSurname(e.target.value)}
                              />
                            </Form.Group>
                          </div>
                        </div>

                        <div className="w-100 d-flex flex-row signup-checkbox">
                          <input
                            type="checkbox"
                            name="signup-checkbox"
                            id="signup-checkbox"
                          />
                          <label className="pl-2" htmlFor="signup-checkbox">
                            <a href="#">Kişisel Veriler Hakkında Bildirim</a> ve{" "}
                            <a href="#">Aydınlatma Metni</a>’ni okudum, anladım
                            ve kabul ediyorum.
                          </label>
                        </div>

                        <div className="w-100 d-flex flex-row signup-buttons">
                          <div className="w-50">
                            <Link
                              to={{ pathname: "/login" }}
                              className="btn btn-outline-danger"
                            >
                              İptal
                            </Link>
                          </div>

                          <div className="w-50">
                            <Button block variant="success" type="submit">
                              Kayıt Ol
                            </Button>
                          </div>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
};

export default SingUp;
