import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "redux/modules";
import { useHistory } from "react-router-dom";

const User = props => {
  const dispatch = useDispatch();

  const history = useHistory();

  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const isLoading = useSelector(store => store.user.isLoading);

  useEffect(() => {
    //console.log(employeeFirm);

    return () => {};
  }, [employeeFirm]);

  return <div>{employeeFirm && employeeFirm.id}</div>;
};

export default User;
