import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authAction, userAction } from "redux/modules";
import showErrMessage from "hooks/showErrMessage";
import { useHistory, Link, Redirect } from "react-router-dom";
import showMessage from "hooks/showMessage";
import showInfoMessage from "hooks/showInfoMessage";
import "./login.css";
import "../../assets/styles/public.css";
import Logo from "assets/images/logo.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faMedium,
  faLinkedinIn
} from "@fortawesome/free-brands-svg-icons";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import {
  faBell,
  faComments,
  faCompass,
  faEnvelopeOpen,
  faPenNib,
  faQuestion,
  faUserCircle
} from "@fortawesome/free-solid-svg-icons";
import PublicFooter from "../PublicFooter/PublicFooter";

const Login = props => {
  const dispatch = useDispatch();
  const act = useSelector(store => store.auth);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [disabledButton, setDisabledButton] = useState(false);
  const [redirect, setRedirect] = useState(false);

  function redirectToHome() {
    setTimeout(() => {
      setRedirect(true);
    }, 200);
  }

  useEffect(() => {
    if (act.isAuthenticated !== null && act.authUser !== null) {
      redirectToHome();
    }
  }, [act]);

  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  const login = (username, password) => {
    setDisabledButton(true);
    dispatch(
      authAction.login({
        username,
        password
      })
    )
      .then(e => {
        console.log(e.data);
        setDisabledButton(true);
        dispatch(userAction.setLoginedUser("logined"));
      })
      .catch(err => {
        console.log(err);
        setDisabledButton(false);
        if (err.error.response.data)
          if (err.error.response.data.errors) {
            err.error.response.data.errors.map(item => {
              showErrMessage(item.message);
            });
          }
      });
  };

  return (
    <div className="public-container">
      <div className="content">
        <div className="public-container-content">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <header className="text-center">
                  <img src={Logo} alt="Jarvis Connection" />
                  <h1>Hoş Geldiniz</h1>
                </header>
                <section className="login-page">
                  <Row className="justify-content-md-center">
                    <Col xs={12} md={6}>
                      <Form>
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Üye Adı</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Üye Adı Yazınız"
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Şifre</Form.Label>
                          <Form.Control
                            type="password"
                            placeholder="Şifre Giriniz"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                          />
                        </Form.Group>

                        <div className="text-right mb-4">
                          <Link
                            className="text-success"
                            to={{ pathname: "/passwordReset" }}
                          >
                            Şifremi Unuttum
                          </Link>
                        </div>

                        <div>
                          <Button
                            block
                            variant="success"
                            type="submit"
                            disabled={disabledButton}
                            onClick={() => login(username, password)}
                          >
                            Giriş Yap
                          </Button>
                        </div>

                        <div>
                          <Link
                            to={{ pathname: "/signUp" }}
                            className="btn btn-outline-success mt-3"
                          >
                            Yeni Üye Ol
                          </Link>
                        </div>
                      </Form>
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
};

export default Login;
