import * as homeTypes from "./home.type";

const initialState = {
  announcementList: null,
  surveyList: null,
  selectedOption: null,
  disclaimerList: null,
  activityList: null,
  error: null,
  isLoading: false
};

const reducer = (state = initialState, { type, payload, ...params }) => {
  switch (type) {
    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_PENDING}`:
    case `${homeTypes.SAVE_SURVEY_ANSWER_PENDING}`:
    case `${homeTypes.SAVE_DISCLAIMER_CHECK_PENDING}`:
    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_PENDING}`:
    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING}`:
      return { ...state, isLoading: true, error: null };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        announcementList: payload.data
      };

    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        surveyList: payload.data
      };

    case `${homeTypes.GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_SURVEYLIST_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${homeTypes.SAVE_SURVEY_ANSWER_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        disclaimerList: payload.data
      };

    case `${homeTypes.GET_DISCLAIMER_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_FULFILLED}`:
      return {
        ...state,
        isLoading: false
      };

    case `${homeTypes.SAVE_DISCLAIMER_CHECK_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED}`:
      return {
        ...state,
        isLoading: false,
        activityList: payload.data
      };

    case `${homeTypes.GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED}`:
      return {
        ...state,
        isLoading: false,
        error: payload
      };

    case `${homeTypes.SELECTED_OPTION}`:
      return {
        ...state,
        selectedOption: payload
      };
    default:
      return state;
  }
};

export default reducer;
