import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter as RouterProvider } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import dayjs from "dayjs";

import App from "./App";

import * as serviceWorker from "./serviceWorker";
import { configureStore } from "./redux/store";
import { appContext } from "./config/app.config";

import "dayjs/locale/tr";
import "./index.css";

dayjs.locale("tr");
const { store, persistor } = configureStore();

const AppProvider = () => {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RouterProvider basename={appContext}>
          <App />
        </RouterProvider>
      </PersistGate>
    </ReduxProvider>
  );
};

ReactDOM.render(<AppProvider />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
