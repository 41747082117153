import React, { useState } from "react";
import { authAction } from "redux/modules";
import { useDispatch } from "react-redux";
import showErrMessage from "hooks/showErrMessage";
import showMessage from "hooks/showMessage";
import { Link, Redirect } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../../assets/images/logo.png";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PublicFooter from "../PublicFooter/PublicFooter";

const PasswordReset = props => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [redirect, setRedirect] = useState(false);

  if (redirect) {
    return <Redirect to="/login" />;
  }

  return (
    <div className="public-container">
      <div className="content">
        <div className="public-container-content">
          <Container>
            <Row>
              <Col xs={12} md={12}>
                <header className="text-center">
                  <a href="/login" className="d-inline-block">
                    <img src={Logo} alt="Jarvis Connection" />
                  </a>

                  <h2>ŞİFREMİ UNUTTUM</h2>
                  <p>
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                    sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                    magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                    quis nostrud exerci tation ullamcorper suscipit lobortis
                    nisl ut aliquip ex ea commodo consequat. Duis autem vel eum
                    iriure dolor in hendrerit in vulputate velit esse molestie
                    consequat, vel illum dolore eu feugiat nulla{" "}
                  </p>
                </header>
                <section className="login-page">
                  <Row className="justify-content-md-center">
                    <Col xs={12} md={6}>
                      <form
                        className=""
                        onSubmit={e => {
                          e.preventDefault();
                        }}
                      >
                        <div className="form-body">
                          <div className="form-el form-el-container">
                            <div className="form-group">
                              <input
                                className="form-control"
                                type="text"
                                placeholder="Üye Adı Yazınız"
                                onChange={e => setUsername(e.target.value)}
                              />
                            </div>
                          </div>

                          <div className="form-btn">
                            <button
                              type="submit"
                              className="btn btn-success"
                              onClick={() =>
                                dispatch(authAction.forgotPassword(username))
                                  .then(e => {
                                    if (e.isHttpSuccess) {
                                      showMessage(
                                        "Yeni şifreniz telefon numaranıza gönderilmiştir"
                                      );
                                    }
                                    setRedirect(true);
                                  })
                                  .catch(err => {
                                    if (err.error.response.data.errors) {
                                      err.error.response.data.errors.map(
                                        item => {
                                          showErrMessage(item.message);
                                        }
                                      );
                                    }
                                  })
                              }
                            >
                              GÖNDER
                            </button>
                          </div>
                        </div>
                      </form>

                      {/*<Form>*/}
                      {/*  <Form.Group controlId="formBasicEmail">*/}
                      {/*    <Form.Label>Üye Adı</Form.Label>*/}
                      {/*    <Form.Control*/}
                      {/*      type="text"*/}
                      {/*      placeholder="Üye Adı Yazınız"*/}
                      {/*      value={username}*/}
                      {/*      onChange={e => setUsername(e.target.value)}*/}
                      {/*    />*/}
                      {/*  </Form.Group>*/}

                      {/*  <Form.Group controlId="formBasicPassword">*/}
                      {/*    <Form.Label>Şifre</Form.Label>*/}
                      {/*    <Form.Control*/}
                      {/*      type="password"*/}
                      {/*      placeholder="Şifre Giriniz"*/}
                      {/*      value={password}*/}
                      {/*      onChange={e => setPassword(e.target.value)}*/}
                      {/*    />*/}
                      {/*  </Form.Group>*/}

                      {/*  <div className="text-right mb-4">*/}
                      {/*    <Link*/}
                      {/*      className="text-success"*/}
                      {/*      to={{ pathname: "/passwordReset" }}*/}
                      {/*    >*/}
                      {/*      Şifremi Unuttum*/}
                      {/*    </Link>*/}
                      {/*  </div>*/}

                      {/*  <div>*/}
                      {/*    <Button*/}
                      {/*      block*/}
                      {/*      variant="success"*/}
                      {/*      type="submit"*/}
                      {/*      disabled={disabledButton}*/}
                      {/*      onClick={() => login(username, password)}*/}
                      {/*    >*/}
                      {/*      Şifremi Unuttum*/}
                      {/*    </Button>*/}
                      {/*  </div>*/}

                      {/*  <div>*/}
                      {/*    <Link*/}
                      {/*      to={{ pathname: "/signUp" }}*/}
                      {/*      className="btn btn-outline-success mt-3"*/}
                      {/*    >*/}
                      {/*      Vazgeç*/}
                      {/*    </Link>*/}
                      {/*  </div>*/}
                      {/*</Form>*/}
                    </Col>
                  </Row>
                </section>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <PublicFooter />
    </div>
  );
};

export default PasswordReset;
