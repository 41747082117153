import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeAction } from "redux/modules";

const HomeSurveyResult = props => {
  const dispatch = useDispatch();
  const employeeFirm = useSelector(store => store.auth.employeeFirm);
  const surveyList = useSelector(store => store.home.surveyList);
  var surveys = null;

  useEffect(() => {
    dispatch(
      homeAction.getSurveyByFirmId(employeeFirm.firm.id, employeeFirm.id)
    );
    return () => {};
  }, []);

  useEffect(() => {
    //    console.log(surveyList);
    return () => {};
  }, [surveyList]);

  function showChoices(choicesList, totalParticipant) {
    var surveyChoices = null;

    surveyChoices = choicesList.map(itemChoice => (
      <div className="options" key={itemChoice.id}>
        <div
          className="row options-div"
          key={itemChoice.id}
          style={{ marginLeft: "0", marginRight: "0" }}
        >
          <div className="col-md-12 col-sm-8 col-xs-8">
            <div dangerouslySetInnerHTML={{ __html: itemChoice.name }}></div>
          </div>
          <div className="col-md-12 col-sm-4 col-xs-4 pr-0">
            <div className="vote text-right">
              %{Math.round((itemChoice.markedValue / totalParticipant) * 100)}
              {/* -{itemChoice.markedValue} oy */}
            </div>
            <div className="d-flex flex-row-reverse">
              <div
                className="bar lightblue"
                style={{
                  width: (itemChoice.markedValue / totalParticipant) * 100 + "%"
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    ));
    return surveyChoices;
  }

  if (surveyList) {
    surveys = surveyList.map(item => {
      return (
        <div key={item.id}>
          <div className="content d-flex flex-column flex-md-row">
            <div className="survey-margin-div" style={{ marginRight: "30px" }}>
              <h2>ANKET</h2>
              <p className="subhead">
                {item.description} <br /> <br />
                Haftanın anket sonuçlarını grafikte görebilirsiniz.
              </p>
            </div>
            <div className="sonuc">
              {showChoices(item.surveyChoices, item.totalParticipant)}
            </div>
          </div>
        </div>
      );
    });
  }

  return <div>{surveys}</div>;
};
export default HomeSurveyResult;
