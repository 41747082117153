export const GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_PENDING";
export const GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_FULFILLED";
export const GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED =
  "GET_ANNOUNCEMENTLIST_BY_FIRMID_REJECTED";

export const GET_SURVEYLIST_BY_FIRMID_PENDING =
  "GET_SURVEYLIST_BY_FIRMID_PENDING";
export const GET_SURVEYLIST_BY_FIRMID_FULFILLED =
  "GET_SURVEYLIST_BY_FIRMID_FULFILLED";
export const GET_SURVEYLIST_BY_FIRMID_REJECTED =
  "GET_SURVEYLIST_BY_FIRMID_REJECTED";

export const SAVE_SURVEY_ANSWER_PENDING = "SAVE_SURVEY_ANSWER_PENDING";
export const SAVE_SURVEY_ANSWER_FULFILLED = "SAVE_SURVEY_ANSWER_FULFILLED";
export const SAVE_SURVEY_ANSWER_REJECTED = "SAVE_SURVEY_ANSWER_REJECTED";

export const GET_DISCLAIMER_BY_FIRMID_PENDING =
  "GET_DISCLAIMER_BY_FIRMID_PENDING";
export const GET_DISCLAIMER_BY_FIRMID_FULFILLED =
  "GET_DISCLAIMER_BY_FIRMID_FULFILLED";
export const GET_DISCLAIMER_BY_FIRMID_REJECTED =
  "GET_DISCLAIMER_BY_FIRMID_REJECTED";

export const SAVE_DISCLAIMER_CHECK_PENDING = "SAVE_DISCLAIMER_CHECK_PENDING";
export const SAVE_DISCLAIMER_CHECK_FULFILLED =
  "SAVE_DISCLAIMER_CHECK_FULFILLED";
export const SAVE_DISCLAIMER_CHECK_REJECTED = "SAVE_DISCLAIMER_CHECK_REJECTED";

export const SELECTED_OPTION = "SELECTED_OPTION";

export const GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_PENDING";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_FULFILLED";
export const GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED =
  "GET_SPECIAL_ACTIVITY_BY_FIRMID_REJECTED";
