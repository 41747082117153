import { combineReducers } from "redux";

import auth from "./auth/auth.reducer";
import user from "./user/user.reducer";
import home from "./home/home.reducer";

const rootReducer = combineReducers({
  auth,
  user,
  home
});

export default rootReducer;
