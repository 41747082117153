import React, { useEffect } from "react";
import Modal from "react-responsive-modal";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userAction } from "redux/modules";
import { API } from "services/http.service";
import { imageShowUrl } from "../../config/api.config";

const HomeFocus = props => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [modalImg, setModalImg] = useState(null);
  const [web, setWeb] = useState();
  const [mob, setMob] = useState();

  useEffect(() => {
    const asyncPopupImg = async () => {
      const { data } = await API.get("focus/Ana Sayfa Pop Up");
      setModalImg(data);
      if (
        data &&
        data[0].webPic != undefined &&
        data[0].mobilPic != undefined
      ) {
        setWeb(data[0].webPic);
        setMob(data[0].mobilPic);
      }
    };
    asyncPopupImg();
  }, [modalImg === null]);

  function closeModal(params) {
    setOpen(!open);
    dispatch(userAction.setLoginedUser("logined"));
  }
  return (
    <div>
      <div>
        {web != undefined && mob != undefined ? (
          <Modal
            open={!open}
            onClose={() => {
              closeModal();
            }}
            role="dialog"
            closeOnEsc={false}
            showCloseIcon={true}
            closeOnOverlayClick={false}
            closeIconId="focus-close-icon"
          >
            <div className="d-none d-sm-block web">
              <img src={imageShowUrl + web} alt="" />
            </div>
            <div className="d-block d-sm-none mobile">
              <img src={imageShowUrl + mob} alt="" />
            </div>
          </Modal>
        ) : null}
      </div>
    </div>
  );
};
export default HomeFocus;
